import { getMainImageSource } from "~/components/shared/Image";
import type * as typo3 from "~/modules/typo3/schema";
import { isNonNullable } from "~/utils/nonNullable";

export function typo3Iterate<T>(
	count: number,
	entries: Record<string, T>,
): Array<undefined | T> {
	const res: Array<undefined | T> = [];
	for (let i = 1; i <= count; i += 1) {
		res.push(entries[String(i)]);
	}
	return res;
}

export function getAllChildContentElementsForResponse(
	res: typo3.Response,
): Array<typo3.AnyContentElement> {
	return Object.values(res.content)
		.flat()
		.filter(isNonNullable)
		.flatMap((c) => {
			const elems = getAllChildContentElementsForContentElement(c);
			elems.push(c);
			return elems;
		});
}

export function getDirectChildContentElementsForContentElement(
	ce: typo3.AnyContentElement,
): Array<typo3.AnyContentElement> {
	switch (ce.type) {
		case "nnbuhaarticlesandpersons_articles":
			if (ce.content.data.action === "show") {
				return ce.content.data.article.contentElements ?? [];
			}
			break;

		case "nnbuhaarticlesandpersons_persons":
		case "nnbuhaarticlesandpersons_personlist":
			if (ce.content.data.action === "show") {
				return ce.content.data.person.contentElements ?? [];
			}
			break;
	}

	return [];
}

export function getAllChildContentElementsForContentElement(
	ce: typo3.AnyContentElement,
): Array<typo3.AnyContentElement> {
	return getDirectChildContentElementsForContentElement(ce).flatMap(
		(child) => {
			const childOffspring =
				getAllChildContentElementsForContentElement(child);
			childOffspring.push(child);
			return childOffspring;
		},
	);
}

export function imageToSimpleImage(img: typo3.Image): typo3.SimpleImage {
	const mainSrc = getMainImageSource(img.imageSources.main);
	return {
		...img,
		publicUrl: mainSrc.url,
		properties: {
			...img.properties,
			cropDimensions: {
				width: mainSrc.width,
				height: mainSrc.height,
			},
		},
	};
}

export function getQuasiEmpty<T>(value: "" | T): null | T {
	return value === "" ? null : value;
}
