import { z, type ZodType } from "zod";

import * as form from "./form";

export { form };

export const eventTypes = [
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9",
	"10",
	"11",
] as const;
export type EventType = (typeof eventTypes)[number];

export function isEventType(val: unknown): val is EventType {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return eventTypes.includes(val as any);
}

export const projectTypes = [
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"9",
	"10",
	"11",
	"12",
	"13",
	"14",
] as const;
export type ProjectType = (typeof projectTypes)[number];

export function isProjectType(val: unknown): val is ProjectType {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return projectTypes.includes(val as any);
}

const htmlContentSchema = z.string();
export type HtmlContent = z.output<typeof htmlContentSchema>;

const quasiBooleanSchema = z.literal(0).or(z.literal(1));
const quasiEmptySchema = z.literal("");

const simpleImageSchema = z.object({
	publicUrl: z.string(),
	properties: z.object({
		title: z.string().nullable(),
		alternative: z.string().nullable(),
		description: z.string().nullable(),
		link: z.string().nullable(),
		cropDimensions: z.object({
			width: z.number().int(),
			height: z.number().int(),
		}),
	}),
});
export type SimpleImage = z.output<typeof simpleImageSchema>;

const imageSourceSchema = z.object({
	url: z.string(),
	width: z.number().int(),
	height: z.number().int(),
});
export type ImageSource = z.output<typeof imageSourceSchema>;

const imageSourceSizesSchema = z.array(imageSourceSchema).nonempty();
export type ImageSourceSizes = z.output<typeof imageSourceSizesSchema>;

const imageSourcesSchema = z.object({
	main: imageSourceSizesSchema,
	thumbnail: imageSourceSizesSchema.optional(),
	thumbnailFree: imageSourceSizesSchema.optional(),
	bobble: imageSourceSizesSchema.optional(),
});
export type ImageSources = z.output<typeof imageSourcesSchema>;
export type ImageSourceName = keyof ImageSources;

const sharedImageVideoSchema = z.object({
	publicUrl: z.string(),
	imageSources: imageSourcesSchema,
	properties: z.object({
		title: z.string().nullable(),
		alternative: z.string().nullable(),
		description: z.string().nullable(),
		extension: z.string().nullable(),
		link: z.string().nullable(),
		mimeType: z.string(),
		autoplay: quasiBooleanSchema,
		filename: z.string(),
		originalUrl: z.string().nullable(), // hotfix
	}),
});

const audioSchema = sharedImageVideoSchema.extend({
	type: z.literal("audio"),
});
export type Audio = z.output<typeof audioSchema>;

const videoSchema = sharedImageVideoSchema.extend({
	type: z.literal("video"),
});
export type Video = z.output<typeof videoSchema>;

const imageSchema = sharedImageVideoSchema.extend({
	type: z.literal("image"),
});
export type Image = z.output<typeof imageSchema>;

const mediaSchema = z.discriminatedUnion("type", [
	imageSchema,
	videoSchema,
	audioSchema,
]);
export type Media = z.output<typeof mediaSchema>;

const fileSchema = z.object({
	type: z.literal("file"),
	publicUrl: z.string(),
	properties: z.object({
		title: z.string().nullable(),
		description: z.string().nullable(),
		extension: z.string(),
		filename: z.string(),
		mimeType: z.string(),
	}),
});
export type File = z.output<typeof fileSchema>;

const navigationItemLeafSchema = z.object({
	title: z.string(),
	subtitle: z.string().nullable().optional(),
	abbreviation: z.string().nullable().optional(),
	thumbnail: z.array(simpleImageSchema).nullable().optional(),
	link: z.string(),
	target: z.literal("_blank").or(z.string()),
	active: quasiBooleanSchema,
	current: quasiBooleanSchema,
	spacer: quasiBooleanSchema,
	hasSubpages: quasiBooleanSchema,
});
export type NavigationItem = z.output<typeof navigationItemLeafSchema> & {
	children?: Array<NavigationItem>;
};

const navigationItemSchema: z.ZodType<NavigationItem> =
	navigationItemLeafSchema.extend({
		children: z.lazy(() => navigationItemSchema.array().optional()),
	});

const i18nItemSchema = z.object({
	languageId: z.number(),
	locale: z.string(),
	title: z.string(),
	navigationTitle: z.string(),
	twoLetterIsoCode: z.string(),
	hreflang: z.string(),
	direction: z.string(),
	flag: z.string(),
	link: z.string(),
	active: quasiBooleanSchema,
	current: quasiBooleanSchema,
	available: quasiBooleanSchema,
});
export type I18nItem = z.output<typeof i18nItemSchema>;

const metadataSchema = z.object({
	title: z.string(),
	subtitle: z.string(),
	abstract: z.string(),
	description: z.string(),
	keywords: z.string(),
	canonical: z.string(),
	robots: z.object({
		noIndex: z.boolean(),
		noFollow: z.boolean(),
	}),
	layout: z.string().nullable(),
	author: z.string(),
	authorEmail: z.string(),
	ogTitle: z.string(),
	ogDescription: z.string(),
	ogImage: z.object({}).nullable(), // TODO shape
	twitterTitle: z.string(),
	twitterDescription: z.string(),
	twitterCard: z.string(),
	twitterImage: z.object({}).nullable(),
	schema: z.string().optional(),

	// Parent page
	parentLink: z.string(),
	parentTitle: z.string(),
	parentSubtitle: z.string(),
	parentUid: z.string(),
	parentBackendLayout: z.string(),

	sectionCategory: z
		.object({
			id: z.number(),
			title: z.string(),
		})
		.optional(),
});

const breadcrumbSchema = z.object({
	title: z.string(),
	link: z.string(),
	target: z.string(),
	active: quasiBooleanSchema,
	current: quasiBooleanSchema,
	spacer: quasiBooleanSchema,
});

const breadcrumbsSchema = z.array(breadcrumbSchema);

const colPosSchema = z.number();
export type ColPos = z.output<typeof colPosSchema>;

export type ColPosStr = `colPos${ColPos}`;
const colPosStrSchema = z.string().regex(/colPos\d+/) as ZodType<ColPosStr>;

const spaceSchema = z.string(); //.regex(/-?\d+?/);

const contentElementAppearanceSchema = z.object({
	layout: z.literal("default").or(z.string()),
	frameClass: z.literal("default").or(z.string()),
	spaceBefore: spaceSchema,
	spaceAfter: spaceSchema,
});

const baseContentElementShape = {
	/** content element id */
	id: z.number(),
	colPos: colPosSchema,
	appearance: contentElementAppearanceSchema,
	//categories: z.string(), // TODO: rly a string?
};

const contentElementHeaderLinkSchema = quasiEmptySchema.or(
	z.object({
		href: z.string(),
		target: z.string().nullable(),
		class: z.string().nullable(),
		title: z.string().nullable(),
		linkText: z.string().nullable(),
		//additionalAttributes: z.array(z.string()),
	}),
);

const contentElementHeadingShape = {
	header: z.string(),
	subheader: z.string(),
	headerLayout: z.number(),
	headerPosition: z.string(),
	headerLink: contentElementHeaderLinkSchema,
};

const contentElementWithHeading = z.object({
	...baseContentElementShape,
	content: z.object({
		...contentElementHeadingShape,
	}),
});
export type ContentElementWithHeading = z.output<
	typeof contentElementWithHeading
>;

export const isContentElementWithHeading = (
	contentElement: AnyContentElement,
): contentElement is AnyContentElement & {
	content: { header: string };
} => "content" in contentElement && "header" in contentElement.content;

const contentElementUnknownSchema = z.object({
	...baseContentElementShape,
	type: z.enum([
		"textmedia",
		"textpic",
		"mailform",
		"list", // aka Plugin
	]),
});
export type UnknownContentElement = z.output<
	typeof contentElementUnknownSchema
>;

const contentElementHeaderSchema = z.object({
	...baseContentElementShape,
	type: z.literal("header"),
	content: z.object({
		...contentElementHeadingShape,
	}),
});
export type ContentElementHeader = z.output<typeof contentElementHeaderSchema>;

const contentElementTextSchema = z.object({
	...baseContentElementShape,
	type: z.literal("text"),
	content: z.object({
		...contentElementHeadingShape,
		bodytext: htmlContentSchema,
	}),
});
export type ContentElementText = z.output<typeof contentElementTextSchema>;

export function isContentElementText(
	ce: AnyContentElement,
): ce is ContentElementText {
	return ce.type === "text";
}

export const tableHeaderPosition = {
	none: 0,
	top: 1,
	left: 2,
} as const;

const contentElementTableSchema = z.object({
	...baseContentElementShape,
	type: z.literal("table"),
	content: z.object({
		...contentElementHeadingShape,
		bodytext: z.array(z.array(z.string())),
		tableCaption: z.string(),
		cols: z.number(),
		tableHeaderPosition: z
			.literal(tableHeaderPosition.none)
			.or(z.literal(tableHeaderPosition.top))
			.or(z.literal(tableHeaderPosition.left)),
		tableClass: z.string(),
	}),
});
export type ContentElementTable = z.output<typeof contentElementTableSchema>;

const contentElementImageSchema = z.object({
	...baseContentElementShape,
	type: z.literal("image"),
	content: z.object({
		...contentElementHeadingShape,
		enlargeImageOnClick: z.boolean(),
		images: z.array(mediaSchema),
	}),
});
export type ContentElementImage = z.output<typeof contentElementImageSchema>;

const contentElementVideoSchema = z.object({
	...baseContentElementShape,
	type: z.literal("video"),
	content: z.object({
		...contentElementHeadingShape,
		video: z.array(videoSchema),
	}),
});
export type ContentElementVideo = z.output<typeof contentElementVideoSchema>;

const documentDownloadSchema = z.object({
	publicUrl: z.string(),
	properties: z.object({
		title: z.string().nullable(),
		extension: z.string(),
		filename: z.string(),
		mimeType: z.string(),
	}),
});
export type DocumentDownload = z.output<typeof documentDownloadSchema>;

const contentElementUploadsSchema = z.object({
	...baseContentElementShape,
	type: z.literal("uploads"),
	content: z.object({
		...contentElementHeadingShape,
		media: z.array(documentDownloadSchema),
	}),
});
export type ContentElementUploads = z.output<
	typeof contentElementUploadsSchema
>;

const contentElementHTMLSchema = z.object({
	...baseContentElementShape,
	type: z.literal("html"),
	content: z.object({
		bodytext: htmlContentSchema,
	}),
});
export type ContentElementHTML = z.output<typeof contentElementHTMLSchema>;

const contentElementApplicationSchema = z.object({
	...baseContentElementShape,
	type: z.literal("nnbuha22base_application"),
	content: z.object({
		header: z.string(),
		bodytext: htmlContentSchema,
	}),
});
export type ContentElementApplication = z.output<
	typeof contentElementApplicationSchema
>;

const contentElementFormSchema = z.object({
	...baseContentElementShape,
	type: z.literal("form_formframework"),
	content: z.object({
		...contentElementHeadingShape,
		formAction: z.string(),
		form: z.object({
			id: z.string(),
			api: form.apiSchema,
			elements: form.elementsSchema,
		}),
	}),
});
export type ContentElementForm = z.output<typeof contentElementFormSchema>;

export const menuContentElementTypes = [
	"menu_sitemap",
	"menu_sitemap_pages",
	"menu_pages",
	"menu_subpages",
] as const;

const contentElementMenuSchema = z.object({
	...baseContentElementShape,
	type: z.enum(menuContentElementTypes),
	content: z.object({
		...contentElementHeadingShape,
		menu: z.array(navigationItemSchema),
	}),
});
export type ContentElementMenu = z.output<typeof contentElementMenuSchema>;

const navigationItemContentSchema = navigationItemLeafSchema.extend({
	content: z
		.object({
			uid: z.string(),
			header: z.string(),
		})
		.array(),
});
export type NavigationContentItem = z.output<
	typeof navigationItemContentSchema
>;

const contentElementMenuSectionSchema = z.object({
	...baseContentElementShape,
	type: z.literal("menu_section"),
	content: z.object({
		...contentElementHeadingShape,
		menu: z.array(navigationItemContentSchema),
	}),
});
export type ContentElementMenuSection = z.output<
	typeof contentElementMenuSectionSchema
>;

const contentElementSupernewsSchema = z.object({
	...baseContentElementShape,
	type: z.literal("nnbuha22base_supernews"),
	supernews: z.array(
		z.object({
			header: z.string(),
			subheader: z.string(),
			headerLink: contentElementHeaderLinkSchema,
			media: z.array(mediaSchema),
			layout: z.enum(["cover", "contain"]),
		}),
	),
});
export type ContentElementSupernews = z.output<
	typeof contentElementSupernewsSchema
>;

const relatedPersonSchema = z.object({
	uid: z.number(),
	fullName: z.string(),
	job: z.string(),
	image: mediaSchema.nullable(),
	detailsUrl: z.string().optional(),
});

export type RelatedPerson = z.output<typeof relatedPersonSchema>;

/** Reduced article data, actual content is stripped */
const sharedArticleCardSchema = z.object({
	articleTypeCategory: z
		.object({
			uid: z.number(),
			title: z.string(),
		})
		.nullable(),
	articleSubtypeCategory: z
		.object({
			uid: z.number(),
			title: z.string(),
		})
		.nullable(),
	hidden: z.boolean(),
	isSubArticle: z.boolean(),
	relatedMedia: z.array(mediaSchema),
	relatedPersons: z.array(relatedPersonSchema),
	sectionCategories: z.array(
		z.object({
			uid: z.number(),
			title: z.string(),
			description: z.string(),
		}),
	),
	teasertext: z.string(),
	title: z.string(),
	uid: z.number(),
	detailsUrl: z.string().optional(),
	pubDate: z.string().optional(),
	isMedia: z.boolean().optional(),
	isEvent: z.boolean(),
	eventType: z
		.enum(["", "0", ...eventTypes, "Digital", "Draussen"] as const)
		.optional()
		.nullable(),
	eventDate: z.string().optional().nullable(),
	eventEndDate: z.string().optional().nullable(),
	eventStartEndtime: z.string().optional().nullable(),
	eventPlace: z.string().optional().nullable(),
});

const basicSharedArticleSchema = sharedArticleCardSchema.extend({
	content: htmlContentSchema,
	context: htmlContentSchema,
	contextRelatedMedia: z.array(mediaSchema),
	relatedFiles: z.array(
		z.object({
			publicUrl: z.string(),
			properties: z.object({
				title: z.string().nullable(),
				alternative: z.string().nullable(),
				description: z.string().nullable(),
				link: z.string().nullable(),
				extension: z.string(),
				mimeType: z.string(),
				filename: z.string(),
				originalUrl: z.string(),
			}),
		}),
	),
});

const sharedArticleSchema: z.ZodType<
	z.output<typeof basicSharedArticleSchema> & {
		contentElements?: Array<AnyContentElement>;
	}
> = basicSharedArticleSchema.extend({
	contentElements: z.lazy(() => z.array(anyContentElementSchema).optional()),
});

const distinctArticleLehrangebotSchema = z.object({
	articleType: z.literal("lehrangebot"),
	courseModulAngebotColor: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseModulAngebotTitle: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseModulAngebotDescription: z.string().optional().nullable(), // TODO: Really optional + nullable?
});

const lehrangebotDetails = {
	courseEcts: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseEinschreibefrist: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseRegistrationStart: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseRegistrationEnd: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseBegin: z.string().optional().nullable(), // TODO: Really optional + nullable?
	coursePlace: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseTime: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseBesprechungszeit: z.string().optional().nullable(), // TODO: Really optional + nullable?
	coursePflichtkennzeichen: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseRequirements: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseErgaenzungsangebote: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseStudienjahr: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseGuests: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseFinalDegree: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseBenotet: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseBezeichnung: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseKontakt: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseModulAngebot: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseSemester: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseSemesterTyp: z.enum(["", "WiSe", "SoSe"]).optional().nullable(), // TODO: Really optional + nullable?
	courseSemesterYear: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseVeranstaltungsart: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseWochentypString: z.string().optional().nullable(), // TODO: Really optional + nullable?
	courseZeitenUndTermine: z.string().optional().nullable(), // TODO: Really optional + nullable?
};

const lehrangebotCardSchema = sharedArticleCardSchema.and(
	distinctArticleLehrangebotSchema,
);
export type LehrangebotCard = z.output<typeof lehrangebotCardSchema>;

const lehrangebotSchema = sharedArticleSchema.and(
	distinctArticleLehrangebotSchema.extend(lehrangebotDetails),
);
export type Lehrangebot = z.output<typeof lehrangebotSchema>;

export type RelatedProject = z.output<typeof relatedProjectSchema>;
const relatedProjectSchema = z.object({
	uid: z.number(),
	title: z.string(),
	detailsUrl: z.string(),
	teasertext: z.string(),
	students: z.string(),
	relatedMedia: z.array(mediaSchema),
});

const distinctArticleProjectSchema = z.object({
	articleType: z.literal("projekt"),
	projectType: z.string().optional(),
	isGroupProject: z.boolean().optional(),
	students: z.string().optional(),
	subtitle: z.string().optional(),
});

const projectCardSchema = sharedArticleCardSchema.and(
	distinctArticleProjectSchema,
);
export type ProjectCard = z.output<typeof projectCardSchema>;

const projectDetails = {
	subArticles: z.array(relatedProjectSchema).optional(),
	articleParent: relatedProjectSchema.nullable().optional(),
	siblingArticles: z.array(relatedProjectSchema).optional(),
};

const projectSchema = sharedArticleSchema.and(
	distinctArticleProjectSchema.extend(projectDetails),
);
export type Project = z.output<typeof projectSchema>;

const distinctArticlePublicationSchema = z.object({
	articleType: z.literal("publikation"),
});

const publicationCardSchema = sharedArticleCardSchema.and(
	distinctArticlePublicationSchema,
);
export type PublicationCard = z.output<typeof publicationCardSchema>;

const publicationSchema = sharedArticleSchema.and(
	distinctArticlePublicationSchema,
);
export type Publication = z.output<typeof publicationSchema>;

const distinctArticleMitteilungSchema = z.object({
	articleType: z.literal("mitteilung"),
});

const distinctArticleTermineSchema = z.object({
	articleType: z.literal("termin"),
});

const articleCardSchema = z
	.discriminatedUnion("articleType", [
		distinctArticleMitteilungSchema,
		distinctArticlePublicationSchema,
		distinctArticleTermineSchema,
		distinctArticleLehrangebotSchema,
		distinctArticleProjectSchema,
	])
	.and(sharedArticleCardSchema);
export type ArticleCard = z.output<typeof articleCardSchema>;

const articleSchema = z
	.discriminatedUnion("articleType", [
		distinctArticleMitteilungSchema,
		distinctArticlePublicationSchema,
		distinctArticleTermineSchema,
		distinctArticleLehrangebotSchema.extend(lehrangebotDetails),
		distinctArticleProjectSchema.extend(projectDetails),
	])
	.and(sharedArticleSchema);
export type Article = z.output<typeof articleSchema>;

const contentElementArticlesSchema = z.object({
	...baseContentElementShape,
	type: z.enum([
		"nnbuhaarticlesandpersons_articles",
		"nnbuhaarticlesandpersons_articlestable",
	]),
	content: z.object({
		...contentElementHeadingShape,
		data: z.discriminatedUnion("action", [
			z.object({
				action: z.literal("list"),
				articles: z.array(articleCardSchema),
			}),
			z.object({
				action: z.literal("show"),
				article: articleSchema,
			}),
		]),
	}),
});
export type ContentElementArticles = z.output<
	typeof contentElementArticlesSchema
>;

const contentElementMediaSchema = z.object({
	...baseContentElementShape,
	type: z.literal("nnbuhaarticlesandpersons_media"),
	content: z.object({
		...contentElementHeadingShape,
		data: z.object({
			action: z.literal("list"),
			articles: z.array(articleCardSchema),
		}),
	}),
});
export type ContentElementMedia = z.output<typeof contentElementMediaSchema>;

const contentElementPodcastStripeSchema = z.object({
	...baseContentElementShape,
	type: z.literal("nnbuhaarticlesandpersons_podcaststripe"),
	content: z.object({
		...contentElementHeadingShape,
		data: z.object({
			action: z.literal("list"),
			articles: z.array(articleCardSchema),
		}),
	}),
});
export type ContentElementPodcastStripe = z.output<
	typeof contentElementPodcastStripeSchema
>;

const contentElementMediaQuartetSchema = z.object({
	...baseContentElementShape,
	type: z.literal("nnbuhaarticlesandpersons_mediaquartet"),
	content: z.object({
		...contentElementHeadingShape,
		data: z.object({
			action: z.literal("list"),
			articles: z.array(articleCardSchema),
		}),
	}),
});
export type ContentElementMediaQuartetStripe = z.output<
	typeof contentElementMediaQuartetSchema
>;

const optionsFacetSchema = z.object({
	type: z.literal("options"),
	name: z.string(),
	label: z.string(),
	options: z.array(
		z.object({
			value: z.union([z.number(), z.string()]),
			label: z.string(),
			count: z.number(),
			active: z.boolean(),
			uris: z.object({
				set: z.string(),
				unset: z.string(),
			}),
		}),
	),
});
export type SolrOptionsFacet = z.output<typeof optionsFacetSchema>;

const rangeDateFacetSchema = z.object({
	type: z.literal("rangeDate"),
	name: z.string(),
	label: z.string(),
	min: z.string(),
	max: z.string(),
	minSelected: z.string(),
	maxSelected: z.string(),
	uris: z.object({
		set: z.string(),
		unset: z.string(),
	}),
});

export type SolrRangeDateFacet = z.output<typeof rangeDateFacetSchema>;

const facetSchema = z.discriminatedUnion("type", [
	optionsFacetSchema,
	rangeDateFacetSchema,
]);

export type SolrFacet = z.output<typeof facetSchema>;

export const solrDataSchema = z.object({
	template: z.enum([
		"default",
		"article",
		"event",
		"lehrangebot",
		"person",
		"project",
		"publication",
		"sectionProject",
		"sectionArchive",
		"media",
		"news",
		"personType",
	]),
	form: z
		.object({
			pluginNamespace: z.string(),
			name: z.string(),
			value: z.string().nullable(),
			resetUri: z.string(),
		})
		.optional(),
	facets: z.array(facetSchema),
	sorting: z.any().optional(),
	documents: z
		.object({
			pagination: z.object({
				next: z.string().nullable(),
			}),
			list: z.object({
				count: z.number(),
				results: z.array(z.lazy(() => searchResultDocumentSchema)),
			}),
		})
		.nullable()
		.optional(),
	allResultCount: z.number().optional(),
	resultsPerPage: z.number().optional(),
});
export type SolrData = z.output<typeof solrDataSchema>;
export type SolrTemplate = SolrData["template"];

const contentElementSolrSchema = z.object({
	...baseContentElementShape,
	type: z.literal("solr_pi_results"),
	content: z.object({
		...contentElementHeadingShape,
		data: solrDataSchema,
	}),
});
export type ContentElementSolr = z.output<typeof contentElementSolrSchema>;

const contentElementEventsSchema = z.object({
	...baseContentElementShape,
	type: z.literal("nnbuhaarticlesandpersons_events"),
	content: z.object({
		...contentElementHeadingShape,
		data: z.discriminatedUnion("action", [
			z.object({
				action: z.literal("list"),
				articles: z.array(articleCardSchema),
			}),
			z.object({
				action: z.literal("show"),
				article: articleSchema,
			}),
		]),
	}),
});
export type ContentElementEvents = z.output<typeof contentElementEventsSchema>;

export const calendarDataSchema = z.object({
	action: z.literal("calendar"),
	today: z.array(sharedArticleCardSchema),
	current: z.array(sharedArticleCardSchema),
	upcoming: z.array(sharedArticleCardSchema),
	highlights: z.array(sharedArticleCardSchema),
	inSection: z.array(sharedArticleCardSchema),
});
export type CalendarData = z.output<typeof calendarDataSchema>;

const contentElementCalendarSchema = z.object({
	...baseContentElementShape,
	type: z.literal("nnbuhaarticlesandpersons_calendar"),
	content: z.object({
		...contentElementHeadingShape,
		data: calendarDataSchema,
	}),
});
export type ContentElementCalendar = z.output<
	typeof contentElementCalendarSchema
>;

const contentElementLehrangeboteSchema = z.object({
	...baseContentElementShape,
	type: z.enum([
		"nnbuhaarticlesandpersons_lehrangebote",
		"nnbuhaarticlesandpersons_lehrangeboteselected",
	]),
	content: z.object({
		...contentElementHeadingShape,
		data: z.discriminatedUnion("action", [
			z.object({
				action: z.literal("list"),
				articles: z.array(lehrangebotCardSchema),
			}),
			z.object({
				action: z.literal("show"),
				article: lehrangebotSchema,
			}),
		]),
	}),
});
export type ContentElementLehrangebote = z.output<
	typeof contentElementLehrangeboteSchema
>;

const contentElementProjectsSchema = z.object({
	...baseContentElementShape,
	type: z.enum([
		"nnbuhaarticlesandpersons_projects",
		"nnbuhaarticlesandpersons_projectsselected",
	]),
	content: z.object({
		...contentElementHeadingShape,
		data: z.discriminatedUnion("action", [
			z.object({
				action: z.literal("list"),
				articles: z.array(projectCardSchema),
			}),
			z.object({
				action: z.literal("show"),
				article: projectSchema,
			}),
		]),
	}),
});
export type ContentElementProjects = z.output<
	typeof contentElementProjectsSchema
>;

const contentElementPublicationsSchema = z.object({
	...baseContentElementShape,
	type: z.enum([
		"nnbuhaarticlesandpersons_publications",
		"nnbuhaarticlesandpersons_publicationsselected",
	]),
	content: z.object({
		...contentElementHeadingShape,
		data: z.discriminatedUnion("action", [
			z.object({
				action: z.literal("list"),
				articles: z.array(publicationCardSchema),
			}),
			z.object({
				action: z.literal("show"),
				article: publicationSchema,
			}),
		]),
	}),
});
export type ContentElementPublications = z.output<
	typeof contentElementPublicationsSchema
>;

const personCardSchema = z.object({
	uid: z.number(),
	fullName: z.string(),
	title: z.string(),
	firstname: z.string(),
	lastname: z.string(),
	job: z.string(),
	info: z.string(),
	address: z.string(),
	email: z.string(),
	phone: z.string(),
	image: mediaSchema.nullable(),
	detailsUrl: z.string().optional(),
});
export type PersonCard = z.output<typeof personCardSchema>;

const basicPersonSchema = personCardSchema.extend({
	content: htmlContentSchema,
	context: htmlContentSchema,
});
export type Person = z.output<typeof basicPersonSchema> & {
	contentElements?: Array<AnyContentElement>;
};

const personSchema: z.ZodType<Person> = basicPersonSchema.extend({
	contentElements: z.lazy(() => z.array(anyContentElementSchema).optional()),
});

const contentElementPersonsSchema = z.object({
	...baseContentElementShape,
	type: z.enum([
		"nnbuhaarticlesandpersons_persons",
		"nnbuhaarticlesandpersons_personlist",
	]),
	content: z.object({
		...contentElementHeadingShape,
		data: z.discriminatedUnion("action", [
			z.object({
				action: z.literal("list"),
				persons: z.array(personCardSchema),
			}),
			z.object({
				action: z.literal("show"),
				person: personSchema,
			}),
		]),
	}),
});
export type ContentElementPersons = z.output<
	typeof contentElementPersonsSchema
>;

const contentElementSlideshowSchema = z.object({
	...baseContentElementShape,
	type: z.literal("nnbuha22base_sildeshow"),
	content: z.object({
		...contentElementHeadingShape,
		images: z.array(mediaSchema),
	}),
});
export type ContentElementSlideshow = z.output<
	typeof contentElementSlideshowSchema
>;

const azItemSchema = z.object({
	...baseContentElementShape,
	type: z.literal("nnbuha22base_azitem"),
	categories: z.array(
		z.object({
			id: z.number(),
			title: z.string(),
		}),
	),
	content: z.object({
		...contentElementHeadingShape,
		bodytext: htmlContentSchema,
		media: z.array(documentDownloadSchema).optional(),
	}),
});
export type AzItem = z.output<typeof azItemSchema>;

const contentElementAzSchema = z.object({
	...baseContentElementShape,
	type: z.literal("nnbuha22base_az"),
	content: z.object({
		...contentElementHeadingShape,
	}),
	items: z.array(azItemSchema),
});
export type ContentElementAz = z.output<typeof contentElementAzSchema>;

const contentElementShortcutSchema = z.object({
	...baseContentElementShape,
	type: z.literal("shortcut"),
	content: z.object({
		shortcut: z.array(z.unknown()), // <- unknown hier, statt lazy
	}),
});
export type ContentElementShortcut = z.output<
	typeof contentElementShortcutSchema
>;

const anyContentElementSchema = z.discriminatedUnion(
	"type",
	[
		contentElementHeaderSchema,
		contentElementTextSchema,
		contentElementHTMLSchema,
		contentElementTableSchema,
		contentElementImageSchema,
		contentElementVideoSchema,
		contentElementUploadsSchema,
		contentElementMenuSchema,
		contentElementFormSchema,
		contentElementArticlesSchema,
		contentElementEventsSchema,
		contentElementCalendarSchema,
		contentElementLehrangeboteSchema,
		contentElementProjectsSchema,
		contentElementPublicationsSchema,
		contentElementPersonsSchema,
		contentElementSlideshowSchema,
		contentElementSolrSchema,
		contentElementUnknownSchema,
		contentElementMenuSectionSchema,
		contentElementMediaSchema,
		contentElementPodcastStripeSchema,
		contentElementMediaQuartetSchema,
		contentElementSupernewsSchema,
		contentElementAzSchema,
		contentElementApplicationSchema,
		contentElementShortcutSchema,
	],
	{
		errorMap: (error, ctx) => {
			if (error.code === "invalid_union_discriminator") {
				return {
					message: `Unknown content element type: ${ctx.data.type}`,
				};
			}
			return { message: ctx.defaultError };
		},
	},
);
//.or(contentElementUnknownSchema);
export type AnyContentElement = z.output<typeof anyContentElementSchema>;

const searchResultSharedSchema = z.object({
	uid: z.number(),
});

const searchResultPageSchema = searchResultSharedSchema.extend({
	type: z.literal("pages"),
	data: z.object({
		title: z.string(),
		subTitle: z.string(),
		navTitle: z.string(),
		abstract: z.string(),
		url: z.string(),
	}),
});

const searchResultArticleSchema = searchResultSharedSchema.extend({
	type: z.literal("tx_nnbuhaarticlesandpersons_domain_model_article"),
	data: articleCardSchema.nullable(),
});

const searchResultPersonSchema = searchResultSharedSchema.extend({
	type: z.literal("tx_nnbuhaarticlesandpersons_domain_model_person"),
	data: personCardSchema,
});

const searchResultDocumentSchema = z.discriminatedUnion("type", [
	searchResultPageSchema,
	searchResultArticleSchema,
	searchResultPersonSchema,
]);
export type SearchResultDocument = z.output<typeof searchResultDocumentSchema>;

const contentSchema = z.record(
	colPosStrSchema,
	z.array(anyContentElementSchema),
);
export type Content = z.output<typeof contentSchema>;

const pageAppearanceSchema = z.object({
	backendLayout: z.string(),
});

export const responseSchema = z.object({
	id: z.number(),
	type: z.string(),
	slug: z.string(),
	meta: metadataSchema,
	breadcrumbs: breadcrumbsSchema,
	appearance: pageAppearanceSchema,
	content: contentSchema,
	projects: quasiEmptySchema.or(solrDataSchema),
	archive: quasiEmptySchema.or(solrDataSchema),
	events: quasiEmptySchema.or(calendarDataSchema),
	i18n: z.array(i18nItemSchema),
	backendUser: z.object({ logged: z.boolean() }),
	frontendUser: z.object({ logged: z.boolean() }),
});
export type Response = z.output<typeof responseSchema>;

export const initialDataSchema = z.object({
	navigation: z.array(navigationItemSchema),
	mainNavigation: contentSchema,
	i18n: z.array(i18nItemSchema),
	specialPages: z.record(z.string(), z.string()),
});

export type InitialData = z.output<typeof initialDataSchema>;

export const isMenuContentElement = (
	content: AnyContentElement,
): content is ContentElementMenu =>
	menuContentElementTypes.includes(
		content.type as (typeof menuContentElementTypes)[number],
	);

export const isSolrContentElement = (
	content: AnyContentElement,
): content is ContentElementSolr => content.type === "solr_pi_results";
export const colPosToNumber = (colPos: ColPosStr): ColPos =>
	Number(colPos.replace("colPos", ""));
