import { z } from "zod";

const validatorNotEmptySchema = z.object({
	identifier: z.literal("NotEmpty"),
});

const validatorStringLengthSchema = z.object({
	identifier: z.literal("StringLength"),
	options: z.object({
		minimum: z.string().optional(),
		maximum: z.string().optional(),
	}),
});

const validatorRegularExpressionSchema = z.object({
	identifier: z.literal("RegularExpression"),
	options: z.object({
		regularExpression: z.string(),
	}),
});

const validatorEmailAddressSchema = z.object({
	identifier: z.literal("EmailAddress"),
});

const validatorIntegerSchema = z.object({
	identifier: z.literal("Integer"),
});

const validatorCountSchema = z.object({
	identifier: z.literal("Count"),
	options: z.object({
		minimum: z.string().optional(),
		maximum: z.string().optional(),
	}),
});

const validatorSchema = z.discriminatedUnion("identifier", [
	validatorNotEmptySchema,
	validatorStringLengthSchema,
	validatorRegularExpressionSchema,
	validatorEmailAddressSchema,
	validatorIntegerSchema,
	validatorCountSchema,
]);
export type Validator = z.output<typeof validatorSchema>;

const sharedElementSchema = z.object({
	identifier: z.string(),
	label: z.string(),
	name: z.string().optional(),
	value: z.string().nullable(),
	defaultValue: z.union([z.string(), z.number()]),
	validators: z.array(validatorSchema).optional(),
});

const elementSingleSelectSchema = sharedElementSchema.extend({
	type: z.literal("SingleSelect"),
	properties: z.object({
		options: z.record(z.string(), z.string()),
		prependOptionLabel: z.string().optional(),
	}),
});
export type ElementSingleSelect = z.output<typeof elementSingleSelectSchema>;

const elementRadioButtonSchema = sharedElementSchema.extend({
	type: z.literal("RadioButton"),
	properties: z.object({
		options: z.record(z.string(), z.string()),
	}),
});
export type ElementRadioButton = z.output<typeof elementRadioButtonSchema>;

const elementMultiCheckboxSchema = sharedElementSchema.extend({
	type: z.literal("MultiCheckbox"),
	properties: z.object({
		options: z.record(z.string(), z.string()),
	}),
});
export type ElementMultiCheckbox = z.output<typeof elementMultiCheckboxSchema>;

const elementMultiSelectSchema = sharedElementSchema.extend({
	type: z.literal("MultiSelect"),
	properties: z.object({
		options: z.record(z.string(), z.string()),
	}),
});
export type ElementMultiSelect = z.output<typeof elementMultiSelectSchema>;

const elementCheckboxSchema = sharedElementSchema.extend({
	type: z.literal("Checkbox"),
});
export type ElementCheckbox = z.output<typeof elementCheckboxSchema>;

const elementTextSchema = sharedElementSchema.extend({
	type: z.literal("Text"),
});
export type ElementText = z.output<typeof elementTextSchema>;

const elementEmailSchema = sharedElementSchema.extend({
	type: z.literal("Email"),
});
export type ElementEmail = z.output<typeof elementEmailSchema>;

const elementDatePickerSchema = sharedElementSchema.extend({
	type: z.literal("DatePicker"),
});
export type ElementDatePicker = z.output<typeof elementDatePickerSchema>;

const elementTextareaSchema = sharedElementSchema.extend({
	type: z.literal("Textarea"),
});
export type ElementTextarea = z.output<typeof elementTextareaSchema>;

const elementStaticTextSchema = sharedElementSchema.extend({
	type: z.literal("StaticText"),
	properties: z.object({
		text: z.string(),
	}),
});
export type ElementStaticText = z.output<typeof elementStaticTextSchema>;

const elementHiddenSchema = sharedElementSchema.extend({
	type: z.literal("Hidden"),
});
export type ElementHidden = z.output<typeof elementHiddenSchema>;

const elementHoneypotSchema = sharedElementSchema.extend({
	type: z.literal("Honeypot"),
});
export type ElementHoneypot = z.output<typeof elementHoneypotSchema>;

const basicElementFieldsetSchema = sharedElementSchema.extend({
	type: z.literal("Fieldset"),
});
const elementFieldsetSchema = basicElementFieldsetSchema.extend({
	elements: z.lazy(() => z.array(elementSchema)),
}) as unknown as typeof basicElementFieldsetSchema &
	z.ZodType<{ elements: Array<Element> }>;
export type ElementFieldset = z.output<typeof elementFieldsetSchema>;

const elementContentElementSchema = sharedElementSchema.extend({
	type: z.literal("ContentElement"),
	properties: z.object({
		contentElement: z.unknown(),
	}),
});
export type ElementContentElement = z.output<
	typeof elementContentElementSchema
>;

export const elementSchema = z.discriminatedUnion("type", [
	elementSingleSelectSchema,
	elementRadioButtonSchema,
	elementMultiCheckboxSchema,
	elementCheckboxSchema,
	elementTextSchema,
	elementEmailSchema,
	elementTextareaSchema,
	elementStaticTextSchema,
	elementHiddenSchema,
	elementHoneypotSchema,
	elementFieldsetSchema,
	elementContentElementSchema,
	elementMultiSelectSchema,
	elementDatePickerSchema,
]);
export type Element = z.output<typeof elementSchema>;

export const elementsSchema = z.array(elementSchema);
export type Elements = z.output<typeof elementsSchema>;

const formSummarySectionSchema = z.object({
	isSection: z.literal(true),
	label: z.string(),
});

const formSummaryElementSchema = z.object({
	isSection: z.literal(false),
	label: z.string(),
	value: z.string(),
});

const formSummaryItemSchema = z.discriminatedUnion("isSection", [
	formSummarySectionSchema,
	formSummaryElementSchema,
]);

export type FormSummaryItem = z.output<typeof formSummaryItemSchema>;

const basePageSchema = z.object({
	current: z.number(),
	pages: z.number(),
	nextPage: z.number().nullable(),
	previousPage: z.number().nullable(),
	label: z.string().optional(),
	renderingOptions: z.object({
		previousButtonLabel: z.string().optional(),
		nextButtonLabel: z.string().optional(),
	}),
});

const formPageSchema = z.discriminatedUnion("type", [
	basePageSchema.extend({
		type: z.literal("Page"),
	}),
	basePageSchema.extend({
		type: z.literal("SummaryPage"),
		summary: z.array(formSummaryItemSchema).optional(),
	}),
	z.object({
		type: z.literal("Result"),
		text: z.string().nullable(),
	}),
]);
export type FormPage = z.output<typeof formPageSchema>;

export const apiSchema = z.object({
	errors: z.record(z.string(), z.string()).nullable(),
	fieldNamePrefix: z.string(),
	page: formPageSchema,
});
export type Api = z.output<typeof apiSchema>;

export const actionResponseSchema = z.object({
	id: z.string(),
	api: apiSchema,
	elements: elementsSchema,
});
export type ActionResponse = z.output<typeof actionResponseSchema>;
